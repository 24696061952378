<html>
    <body>
        <div class="masthead">
            <div class="container page">
                <mat-card  >
                    <div class="title-card" text-center>
                        <mat-label class="title">🤗 WELCOME TO THE INFLUENCE LAB COMMUNITY 🚀</mat-label>
                    </div>
                    <mat-divider ></mat-divider>
                    <mat-card-content class=" mt-4">
                        <form *ngIf="init" class="align-items-center" (change)="SubmitPost()" [formGroup]="influenceur" >
        
                            <div class="row input-row  ">
                                <div class="col-md-6 col-lg-6 col-sm-12   ">
                                    <div class="col-md-12 col-lg-12  display-mid">
                                        <div class="col-lg-4  col-12 col-md-4 ">
                                            <mat-label class="labelFont">First Name *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12  col-md-8 ">
                                            <mat-form-field >
                                                <input  matInput placeholder=""   formControlName="firstname">
                                                <!-- <mat-icon matSuffix *ngIf="!influenceur.get('firstname')?.errors" color="success">check</mat-icon>
                                                <mat-icon matSuffix *ngIf="!influenceur.get('firstname')?.valid" color="error">close</mat-icon> -->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6  col-lg-6   col-sm-12">
                                    <div class="col-md-12 display-mid  col-lg-12">
                                        <div class="col-lg-4 col-12  col-md-4 ">
                                            <mat-label class="labelFont">Last Name  *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8 ">
                                            <mat-form-field >
                                                <input matInput placeholder=""  formControlName="lastname">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div class="row input-row">
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Contact Email  *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field >
                                                <input matInput  placeholder="" [email]="true" formControlName="secondarymail">
                                                <!-- <mat-error *ngIf="influenceur.value['email'].invalid">{{getErrorMessage()}}</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Phone Number *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field>
                                                <input matInput mask="00 00 00 00 00"  placeholder=""  formControlName="phone">
                                                <!-- <mat-error *ngIf="influenceur.value['phone'].invalid">{{getErrorMessagePhone()}}</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div class="row input-row">
                                <div class="col-md-6 col-lg-6 col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Address  *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field >
                                                <input matInput  placeholder=""  formControlName="adress">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-6 col-md-4">
                                            <mat-label class="labelFont">Post Code *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field>
                                                <input matInput  placeholder="" mask="separator.2" formControlName="postcode">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div class="row input-row">
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">City *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field >
                                                <input matInput  placeholder="" formControlName="city">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Country *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field >
                                                <mat-select formControlName="country">
                                                    <mat-option  *ngFor="let country of countries" [value]="country">
                                                        {{ country }}
                                                    </mat-option>
                                                </mat-select>
                                                <input matInput   placeholder="" >                            
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div class="row input-row">
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Gender</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field>
                                                <mat-select formControlName="gender">
                                                    <mat-option [value]="'male'">
                                                        Homme
                                                    </mat-option>
                                                    <mat-option [value]="'female'">
                                                        Femme
                                                    </mat-option>
                                                </mat-select>
                                                <input matInput placeholder=""  >
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Birth Date *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field  >
                                                <input matInput [matDatepicker]="picker"  formControlName="birthday">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div class="row input-row">
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Main Language *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field>
                                                <mat-select  formControlName="language">
                                                    <mat-option *ngFor="let language of languages" [value]="language">
                                                        {{ language }}
                                                    </mat-option>
                                                </mat-select>
                                                <input matInput  placeholder=""  >
                                            </mat-form-field>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Other Languages</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field >
                                                <input matInput placeholder=""  formControlName="secondaryLanguage">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div class="row input-row">
                                <div class="col-md-6 col-lg-6  col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Topics *</mat-label>
                                            <!-- <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <mat-label class="labelFont">Topics *</mat-label>
    
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-12 col-md-12">
                                                    <mat-label class="textsecondary">
                                                        Please choose between 1 and 6 topics
                                                    </mat-label>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <mat-form-field >
                                                <mat-select  formControlName="topics" (selectionChange)="changed()" multiple>
                                                    <mat-select-trigger>
                                                    </mat-select-trigger>
                                                  <mat-option  *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                                                </mat-select>
                                                
                                              </mat-form-field>
                                              <mat-label class="textsecondary">
                                                Please choose between 1 and 6 topics
                                            </mat-label>
                                              <div *ngIf="init === true">
                                                <div  *ngFor="let topic  of influenceur.value['topics']; index as i" class="badge  mt-2 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                                    {{ topic }} 
                                                </div>
                                              </div>
                                              
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12">
                                    <div class="col-md-12 formnotMaterial col-lg-12 display-mid">
                                        <div class="col-lg-4 formnotMaterial col-12 col-md-4">
                                            <mat-label class="labelFont">Add Social Media Accounts</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                            <form class="network-button" (click)="moreSocialNetwork()" action="">
                                                <button mat-mini-fab  style="color:white; background-color: #cc181e;">
                                                    <i  class="fa  fa-youtube "></i>
                                                  </button>
                                                <button mat-mini-fab  style="color:white; background-color: #8a3ab9;">
                                                    <i  class="fa  fa-instagram "></i>
                                                  </button>
                                                  <button mat-mini-fab  style="color:white; background-color: #fccc63;">
                                                    <i  class="fa  fa-bold "></i>
                                                </button>
                                                <button mat-mini-fab  style="color:white; background-color: black;">
                                                    <mat-icon>tiktok</mat-icon>
                                                </button>
                                                <!-- <button mat-mini-fab class="shadow-none"  style="color:white; background-color: #3b5998;">
                                                    <i  class="fa  fa-facebook "></i>
                                                </button> -->
                                                <!-- <button  class="btn  btn-secondary col-lg-12 shadow-none " > <b>Go !</b> </button> -->
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row input-row">
                                <div class="col-md-6 col-lg-6 formnotMaterial col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Keywords *</mat-label>
                                        </div>
                                        <div class="col-lg-8 col-12 col-md-8">
                                                <mat-form-field (change)="addKeyWords()" (keyup)="keytab($event)" >
                                                    <input  formControlName="valueArrTmp" matInput   type="text" >
                                                    <button  matSuffix mat-icon-button aria-label="Clear" >
                                                      <mat-icon>add</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <mat-label class="textsecondary">
                                                    #What describes you the best ?
                                                </mat-label>
                                                <br>
                                                <mat-label class="textsecondary">
                                                    Ex : Hiking ; mountain ; cabin ; wildlife ; wilderness ; outdoors ; natgeotravel
                                                </mat-label>
                                                
                                                <div *ngIf="init === true">
                                                    <div   (click)="deleteKeyWords(i)"  *ngFor="let keyword of influenceur.value['keywords']; index as i" class="badge mt-2 mb-1 mr-1" style="background-color: black; color:white;font-family: Open Sans,Helvetica,Arial,sans-serif!important;font-size: 12px !important; line-height: 1.538461 !important;">
                                                        #{{ keyword }} x
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 formnotMaterial col-sm-12">
                                    <div class="col-md-12 col-lg-12 display-mid">
                                        <div class="col-lg-4 col-12 col-md-4">
                                            <mat-label class="labelFont">Upload Profile Picture</mat-label>
                                        </div>
                                        <div class="col-lg-8  col-12 col-md-8">
                                            <mat-form-field>
                                                <ngx-mat-file-input (change)="addPicture($event)"  ></ngx-mat-file-input>
                                                <mat-icon matSuffix>attach_file</mat-icon>
                                            </mat-form-field>
                                            <mat-label class="textsecondary">
                                               We accept files in the following formats: .png, .jpg, .gif
                                            </mat-label>
                                            <div class="col-lg-12 col-md-12 row" *ngIf="influenceur.value['picture']">
                                                <div class="imgrow">
                                                    <div class="img_wrapper_inf">
                                                        <span (click)="deleteImg(influenceur.value['picture'])" class="close">&times;</span>
                                                        <img  [src]="picService.checkUrlPath(influenceur.value['picture'])" class="img-responsive center-block imgFeedMulti">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <!-- <div class="row input-row mt-2 mb-2 " flex-center>
                                <label type="submit" data-toggle="modal" data-target="#backInfluencerNetwork" (click)="refused()" style="color: #a7a7a7;" class="mr20 ">Cancel</label>
                                    <button mat-button class="btn  btn-secondaryWithBorder ml-5 shadow-none" (click)="openDialog()">Save</button>
                            </div> -->
                            <div class="container mb-2">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12 text-center">
                                        <label type="submit" data-toggle="modal" data-target="#backInfluencerNetwork" (click)="refused()" style="color: #a7a7a7;" class="d-inline">Cancel</label>
                                        <button mat-button class="btn  btn-secondaryWithBorder ml-5 shadow-none" (click)="openDialog()">Save</button>
                                    </div>
                                </div>
                              </div>
                        </form>
                    </mat-card-content>
                </mat-card>
                
            </div>
        </div> 
    </body>
</html>

