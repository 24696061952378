
import { Component, OnInit,ChangeDetectorRef  } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import {MediaInfluenceurService } from 'src/app/service/media-influenceur.service';
import {UserLoginService} from 'src/app/service/user-login.service';
import { ApiService } from 'src/app/service/api.service';
import {userModel} from 'src/app/model/user-model.model'
import {toast} from 'src/app/model/toast'
import { MatDialog} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FacebookDialogComponent } from 'src/app/dialog/facebook-dialog/facebook-dialog.component';
import { InstagramDialogComponent } from 'src/app/dialog/instagram-dialog/instagram-dialog.component';
import { WithoutFbModalComponent } from 'src/app/dialog/without-fb-modal/without-fb-modal.component';
import { InstagramInsightDialogComponent } from 'src/app/dialog/instagram-insight-dialog/instagram-insight-dialog.component';
import { CancelComponent } from 'src/app/dialog/cancel/cancel.component';
import { AddMoreSocialNetworkComponent } from 'src/app/dialog/add-more-social-network/add-more-social-network.component';
import { FinishRegisterComponent } from 'src/app/dialog/finish-register/finish-register.component';
import { RefuseContratComponent } from 'src/app/dialog/refuse-contrat/refuse-contrat.component';

import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router'
@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss', '../../dialog/facebook-dialog/facebook-dialog.component.css', '../../../assets/styles/table.css', '../../../assets/styles/panel.css','../../../assets/styles/text-title.css','../../../assets/styles/button.css','../../../assets/styles/body.css'],
  providers: [MediaInfluenceurService]
})
export class InscriptionComponent implements OnInit {

  clickAcceptContract = false;
  toast = toast
  toppingList: string[] = ['fashion', 'beauty', 'health', 'food', 'homedeco', 'travel', 'automotive', 'tech', 'lifestyle', 'petcare'];
  influenceur: FormGroup;
  keywordList : string[];
  keyword : any;
  currentUser : userModel
  init = false
  countries: Array<string> = ["France", "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua & Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas"
    , "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia & Herzegovina", "Botswana", "Brazil", "British Virgin Islands"
    , "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica"
    , "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea"
    , "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana"
    , "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India"
    , "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia"
    , "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania"
    , "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia"
    , "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal"
    , "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre & Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles"
    , "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea"
    , "Spain", "Sri Lanka", "St Kitts & Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan"
    , "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad & Tobago", "Tunisia"
    , "Turkey", "Turkmenistan", "Turks & Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay"
    , "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"];

  languages: Array<string> = ["French", "Afrikaans", "Albanian", "Amharic", "Arabic", "Aramaic", "Armenian", "Assamese", "Aymara", "Azerbaijani", "Balochi",
    "Bamanankan", "Bashkort (Bashkir)", "Basque", "Belarusan", "Bengali", "Bhojpuri", "Bislama", "Bosnian", "Brahui", "Bulgarian", "Burmese",
    "Cantonese", "Catalan", "Cebuano", "Chechen", "Cherokee", "Croatian", "Czech", "Dakota", "Danish", "Dari", "Dholuo", "Dutch", "English",
    "Esperanto", "Estonian", "Éwé", "Finnish", "French", "Georgian", "German", "Gikuyu", "Greek", "Guarani", "Gujarati", "Haitian Creole",
    "Hausa", "Hawaiian", "Hawaiian Creole", "Hebrew", "Hiligaynon", "Hindi", "Hungarian", "Icelandic", "Igbo", "Ilocano", "Indonesian (Bahasa Indonesia)",
    "Inuit/Inupiaq", "Irish Gaelic", "Italian", "Japanese", "Jarai", "Javanese", "K’iche’", "Kabyle", "Kannada", "Kashmiri", "Kazakh", "Khmer", "Khoekhoe",
    "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin", "Latvian", "Lingala", "Lithuanian", "Macedonian", "Maithili", "Malagasy", "Malay (Bahasa Melayu)", "Malayalam",
    "Mandarin (Chinese)", "Marathi", "Mende", "Mongolian", "Nahuatl", "Navajo", "Nepali", "Norwegian", "Ojibwa", "Oriya", "Oromo", "Pashto", "Persian",
    "Polish", "Portuguese", "Punjabi", "Quechua", "Romani", "Romanian", "Russian", "Rwanda", "Samoan", "Sanskrit", "Serbian", "Shona", "Sindhi", "Sinhala",
    "Slovak", "Slovene", "Somali", "Spanish", "Swahili", "Swedish", "Tachelhit", "Tagalog", "Tajiki", "Tamil", "Tatar", "Telugu", "Thai", "Tibetic languages",
    "Turkish", "Turkmen", "Ukrainian", "Urdu", "Uyghur", "Uzbek", "Vietnamese", "Warlpiri", "Welsh", "Wolof", "Xhosa", "Yakut", "Yiddish", "Yoruba", "Yucatec",
    "Zapotec", "Zulu"]

  constructor( 
    private fb: FormBuilder, 
    private authService : UserLoginService, 
    private apiService : ApiService, 
    public picService: MediaInfluenceurService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cdRef:ChangeDetectorRef
    ) {
      
      
  }
  ngOnInit(): void {
    this.currentUser = this.authService.getUserValue()
    if (!this.currentUser){
      this.apiService.getUser().subscribe(res => {
        if (!res){
          this.authService.logout()
        } else {
          this.authService.setItemForInfluencer(res)
          this.currentUser = res
          this.influenceur = this.fb.group({
            creationDate: new FormControl(this.currentUser.influencerInfo.creationDate),
            firstname: new FormControl(this.currentUser.influencerInfo.firstname, Validators.required),
            lastname: new FormControl(this.currentUser.influencerInfo.lastname, Validators.required),
            secondarymail: new FormControl(this.currentUser.influencerInfo.secondarymail, Validators.required),
            phone: new FormControl(this.currentUser.influencerInfo.phone, Validators.required),
            adress: new FormControl(this.currentUser.influencerInfo.adress, Validators.required),
            postcode: new FormControl(this.currentUser.influencerInfo.postcode, Validators.required),
            city: new FormControl(this.currentUser.influencerInfo.city, Validators.required),
            country: new FormControl(this.currentUser.influencerInfo.country, Validators.required),
            gender: new FormControl(this.currentUser.influencerInfo.gender),
            birthday: new FormControl(this.currentUser.influencerInfo.birthday, Validators.required),
            language: new FormControl(this.currentUser.influencerInfo.language, Validators.required),
            secondaryLanguage: new FormControl(this.currentUser.influencerInfo.secondaryLanguage),
            topics: new FormControl(this.tranformTopicsToArray(this.currentUser.influencerInfo.topics), Validators.required),
            keywords: new FormControl([], Validators.required),
            picture: new FormControl(this.currentUser.influencerInfo.picture),
            valueArrTmp: new FormControl("")
          })
          this.init = true
          this.cdRef.detectChanges();
          if (this.route.snapshot.params.plateformeTypeErr){
            switch (this.route.snapshot.params.plateformeTypeErr) {
              case "withoutFacebook":
                this.openDialogError(WithoutFbModalComponent, {
                  panelClass: 'dialog-container-custom',
                  data: res
                })
                break;
              case "Facebook":
                this.openDialogError(FacebookDialogComponent, {
                  panelClass: 'dialog-container-custom',
                  data: res
                })
                break;
              case "Instagram":
                this.openDialogError(InstagramDialogComponent, {
                  panelClass: 'custom-dialog-container',
                  data: res})
                break;
              case "InstagramInsight":
                this.openDialogError(InstagramInsightDialogComponent, {
                  panelClass: 'custom-dialog-container',
                  data: res})
                break;
              default:
                break;
            }
        }
        }
      })
    } else {
      if (this.route.snapshot.params.plateformeTypeErr){
        switch (this.route.snapshot.params.plateformeTypeErr) {
          case "withoutFacebook":
                this.openDialogError(WithoutFbModalComponent, {
                  panelClass: 'dialog-container-custom',
                  data: this.currentUser
                })
                break;
          case "Facebook":
            this.openDialogError(FacebookDialogComponent, {
              panelClass: 'dialog-container-custom',
              data: this.currentUser
            })
            break;
          case "Instagram":
            this.openDialogError(InstagramDialogComponent, {
              panelClass: 'custom-dialog-container',
              data: this.currentUser})
            break;
          case "InstagramInsight":
            this.openDialogError(InstagramInsightDialogComponent, {
              panelClass: 'custom-dialog-container',
              data: this.currentUser})
            break;
          default:
            break;
        }
    }
    this.apiService.getUser().subscribe(res => {
      if (!res){
        this.authService.logout()
      } else {
        this.authService.setItemForInfluencer(res)
        this.currentUser = res
        this.influenceur = this.fb.group({
          creationDate: new FormControl(this.currentUser.influencerInfo.creationDate),
          firstname: new FormControl(this.currentUser.influencerInfo.firstname, Validators.required),
          lastname: new FormControl(this.currentUser.influencerInfo.lastname, Validators.required),
          secondarymail: new FormControl(this.currentUser.influencerInfo.secondarymail, Validators.required),
          phone: new FormControl(this.currentUser.influencerInfo.phone, Validators.required),
          adress: new FormControl(this.currentUser.influencerInfo.adress, Validators.required),
          postcode: new FormControl(this.currentUser.influencerInfo.postcode, Validators.required),
          city: new FormControl(this.currentUser.influencerInfo.city, Validators.required),
          country: new FormControl(this.currentUser.influencerInfo.country, Validators.required),
          gender: new FormControl(this.currentUser.influencerInfo.gender),
          birthday: new FormControl(this.currentUser.influencerInfo.birthday, Validators.required),
          language: new FormControl(this.currentUser.influencerInfo.language, Validators.required),
          secondaryLanguage: new FormControl(this.currentUser.influencerInfo.secondaryLanguage),
          topics: new FormControl(this.tranformTopicsToArray(this.currentUser.influencerInfo.topics), Validators.required),
          keywords: new FormControl(this.currentUser.influencerInfo.keywords, Validators.required),
          picture: new FormControl(this.currentUser.influencerInfo.picture),
          valueArrTmp: new FormControl("")
        })
        this.init = true
        this.cdRef.detectChanges();
      }
    })
    }
  }

   SubmitPost(){
    if (this.route.snapshot.params.plateformeTypeErr){
      switch (this.route.snapshot.params.plateformeTypeErr) {
        case "Facebook":
          this.openDialogError(FacebookDialogComponent, {
            panelClass: 'dialog-container-custom',
            data: this.currentUser
          })
          break;
        case "Instagram":
          this.openDialogError(InstagramDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: this.currentUser})
          break;
        case "InstagramInsight":
          this.openDialogError(InstagramInsightDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: this.currentUser})
          break;
        default:
          break;
      }
  }
    this.setterInfluencerFormOnJson(this.influenceur)
  }

  initFormUser(){
    this.influenceur = this.fb.group({
      creationDate: new FormControl(this.currentUser.influencerInfo.creationDate),
      firstname: new FormControl(this.currentUser.influencerInfo.firstname, Validators.required),
      lastname: new FormControl(this.currentUser.influencerInfo.lastname, Validators.required),
      secondarymail: new FormControl(this.currentUser.influencerInfo.secondarymail, Validators.required),
      phone: new FormControl(this.currentUser.influencerInfo.phone, Validators.required),
      adress: new FormControl(this.currentUser.influencerInfo.adress, Validators.required),
      postcode: new FormControl(this.currentUser.influencerInfo.postcode, Validators.required),
      city: new FormControl(this.currentUser.influencerInfo.city, Validators.required),
      country: new FormControl(this.currentUser.influencerInfo.country, Validators.required),
      gender: new FormControl(this.currentUser.influencerInfo.gender),
      birthday: new FormControl(this.currentUser.influencerInfo.birthday),
      language: new FormControl(this.currentUser.influencerInfo.language, Validators.required),
      secondaryLanguage: new FormControl(this.currentUser.influencerInfo.secondaryLanguage),
      topics: new FormControl(this.tranformTopicsToArray(this.currentUser.influencerInfo.topics), Validators.required),
      keywords: new FormControl(this.currentUser.influencerInfo.keywords, Validators.required),
      picture: new FormControl(this.currentUser.influencerInfo.picture),
      valueArrTmp: new FormControl("")
    })
    this.init = true
    this.cdRef.detectChanges();
  }


  tranformTopicsToArray(elem: any){
    var arr = []
    if (elem.fashion == true){
      arr.push("fashion")
    }  if (elem.health == true){
      arr.push("health")

    }  if (elem.food == true) {
      arr.push("food")

    }  if (elem.homedeco == true) {
      arr.push("homedeco")

    } if (elem.travel == true) {
      arr.push("travel")

    } if (elem.automotive == true) {
      arr.push("automotive")

    } if (elem.tech == true) {
      arr.push("tech")

    }  if (elem.lifestyle == true) {
      arr.push("lifestyle")
    }  if (elem.petcare == true) {
      arr.push("petcare")

    }
    return arr
  }

  public setterInfluencerFormOnJson(json: any){
    let check = this.authService.isAuthenticated()
    if (check == true){
      this.currentUser.influencerInfo.firstname = json.value['firstname']
      this.currentUser.influencerInfo.lastname = json.value['lastname']
      this.currentUser.influencerInfo.secondarymail = json.value['secondarymail']
      this.currentUser.influencerInfo.phone = json.value['phone']
      this.currentUser.influencerInfo.adress = json.value['adress']
      this.currentUser.influencerInfo.postcode = json.value['postcode']
      this.currentUser.influencerInfo.city = json.value['city']
      this.currentUser.influencerInfo.country = json.value['country']
      this.currentUser.influencerInfo.gender = json.value['gender']
      this.currentUser.influencerInfo.birthday = json.value['birth']
      this.currentUser.influencerInfo.language = json.value['language']
      this.currentUser.influencerInfo.secondaryLanguage = json.value['secondaryLanguage']
      this.currentUser.influencerInfo.keywords = json.value['keywords']
      this.currentUser.influencerInfo.picture = json.value['picture']
      let newtopicsJson = this.setTopics(json.value['topics'])
      this.currentUser.influencerInfo.topics = newtopicsJson
      localStorage.setItem('userInfo', JSON.stringify(this.currentUser))
      this.apiService.putUserInfluenceurInfo(this.currentUser._id, this.currentUser.influencerInfo).subscribe(res => {
      })
    } else {
      console.log("check false")
    }
  }

  setTopics (array : []){
    var newjsonTopics = {
      fashion: false,
      beauty: false,
      health: false,
      food: false,
      homedeco: false,
      travel: false,
      automotive: false,
      tech: false,
      lifestyle: false,
      petcare: false,
    }
    if (array){
      array.forEach(elem => {
        switch (elem) {
          case 'fashion':
            newjsonTopics.fashion = true
            break;
          case 'beauty':
            newjsonTopics.beauty = true
            break;
          case 'health':
            newjsonTopics.health = true
  
            break;
          case 'food':
            newjsonTopics.food = true
  
            break;
          case 'homedeco':
            newjsonTopics.homedeco = true
  
            break;
          case 'travel':
            newjsonTopics.travel = true
  
            break;
          case 'automotive':
            newjsonTopics.automotive = true
  
            break;
          case 'tech':
            newjsonTopics.tech = true
            break;
          case 'lifestyle':
            newjsonTopics.lifestyle = true
            break;
          case 'petcare':
            newjsonTopics.petcare = true
        
          break;
          default:
            break;
        }
      })
        return newjsonTopics
      } else {
        return newjsonTopics
      }
    
    }

    changed() {
      if (this.influenceur.value['topics'].length > 6){
        this.toastr.warning(this.toast.chooseTopics)
        this.influenceur.value['topics'].splice(-1)
        return
      }
    }

  verifiedIndexOf(elem : string) : Boolean {
    let check = false
    this.influenceur.value.keywords.forEach((res : any) => {
     if (elem == res ){
       check = true
     }
    })
    return check
  }

  checkSpaceOnString(str : string) {
    let tmpstr = str.split(" ")
    tmpstr.forEach((elem) =>  {
      if (elem != " "){
        let verif = this.verifiedIndexOf(elem)
        if (verif == false){
          this.influenceur.value['keywords'].push(elem)
          this.influenceur.controls['keywords'].updateValueAndValidity()
          this.cdRef.detectChanges();
        }
      }
    })
  }

  keytab(event: any){
    if ((event && event.keyCode) && event.keyCode == 32 ||event.keyCode == 58 || event.keyCode == 59 || event.keyCode == 188 ||event.keyCode == 51 ||event.keyCode == 9 ||event.keyCode == 32){
      if (this.influenceur.value['valueArrTmp'] && this.influenceur.value['valueArrTmp'].slice(0, -1).length > 0){
        this.checkSpaceOnString(this.influenceur.value['valueArrTmp'].slice(0, -1))
        this.influenceur.controls['valueArrTmp'].setValue("")
      }
    }
  }

  addKeyWords(){
    if (this.influenceur.value['valueArrTmp']){
      this.checkSpaceOnString(this.influenceur.value['valueArrTmp'])
      this.influenceur.controls['valueArrTmp'].setValue("")
    }
  }

  deleteKeyWords(index: number){
    this.influenceur.value['keywords'].splice(index, 1)
    if ( this.influenceur.value['keywords'].length == 0){
      this.influenceur.controls['keywords'].updateValueAndValidity()
    }
    this.cdRef.detectChanges();
  }

  openDialog() {
    
    if (!this.influenceur.get('keywords')?.valid){
      this.toastr.warning(this.toast.chooseKeywords)
      
      return
    }
    if (!this.influenceur.get('birthday')?.valid){
      this.toastr.warning(this.toast.chooseBirthday)
      
      return
    }
    if (!this.currentUser.userObject[0]){
      this.toastr.warning(this.toast.addPage)
      this.moreSocialNetwork()
      return
    }
    if (this.influenceur.valid === true){
      this.setterInfluencerFormOnJson(this.influenceur)
      this.openDialogError(FinishRegisterComponent, {
        panelClass: 'custom-dialog-container',
        data: this.currentUser})
    }
  }

  openDialogError(compenentName: any, option : any) {
      const dialogRef = this.dialog.open(compenentName, option);
      dialogRef.afterClosed().subscribe(result => {
        this.apiService.getUser().subscribe(user => {
          this.currentUser = user
        })
      });
  }

  moreSocialNetwork(){
    
    this.openDialogError(AddMoreSocialNetworkComponent, {
      panelClass: 'dialog-container-custom',
      data: this.currentUser
    })
    
  }
  
  refused(){
    const dialogRef = this.dialog.open(CancelComponent);
    dialogRef.afterClosed().subscribe(result => {
      dialogRef.close('Pizza!');
    });
  }

  addPicture(event : any){
    if(!event.target.files[0] || event.target.files.length == 0) {
      return false;
    }
    var reader = new FileReader();
    reader.onload = () => {
      let extention = this.picService.getExtentionImg(event.target.files[0])
      if (extention != false){
        
        const fd = new FormData();
        fd.append('file', event.target.files[0], event.target.files[0].name);
        this.apiService.postPicture(fd).subscribe(path => {
          this.influenceur.controls['picture'].setValue(path[0]);
          this.cdRef.detectChanges()
        })
        this.cdRef.detectChanges()
        return event.target.files[0]
      }
    }
    reader.readAsDataURL(event.target.files[0]);
    return false
    }
  
    deleteImg(img : any){
      this.influenceur.controls['picture'].setValue("");
      this.apiService.deletePicture(img).subscribe(res => {
        this.cdRef.detectChanges()
      })
    }

}


